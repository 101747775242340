<script setup>
import {useBaseStore} from "~/stores/base";
import {storeToRefs} from 'pinia';

const router = useRouter()
const route = useRoute()

const emits = defineEmits(["close"]);
const props = defineProps({
  open: {
    default: false,
    required: true
  },
  position: {
    default: 'bottom' // bottom, left, top, right
  },
  borderRadius: {
    default: "0 0 0 0"
  },
  border: {
    default: "none"
  },
  zIndex: {
    default: 2, // 不能改成4，为了兼容小屏，因为会使filter等Drawer出现不应该出现的顶部阴影
  },
  btnCloseVisible: {
    default: false,
  },
  frostedGlass: {
    default: true,
  },
  scrollToTopWhenOpen: {
    default: true
  }
});

/** computed **/
const isMobile = computed(() => useBaseStore().getterIsMobile)
const hash = computed(() => route.hash)

/** watch **/
const {
  isShowDrawerSearch,
  isShowDrawerMenu,
  isShowFilter
} = storeToRefs(useBaseStore());
// 将 props 转换为可监听的引用
const { open } = toRefs(props);

// 监听变化
watch([open, isShowDrawerSearch, isShowDrawerMenu, isShowFilter, hash], ([newOpen, newIsShowDrawerSearch, newIsShowDrawerMenu, newIsShowFilter, newHash]) => {
  if (newOpen || newIsShowDrawerSearch || newIsShowDrawerMenu || newIsShowFilter || newHash) {
    if (props.scrollToTopWhenOpen) window.scrollTo(0, 0); // 页面滚动到 x:0, y:0
    setTimeout(() => {
      document.body.style = "overflow: hidden";
    }, 10)
  } else {
    document.body.style = "overflow: auto";
  }
});

const handleClose = () => {
  emits("close");
};
</script>

<template>
  <div class="_drawer-mobile" v-if="isMobile">
    <!-- 背景的淡入淡出 -->
    <transition name="fade">
      <div v-if="open" class="_drawer-background" :class="{frostedGlass}" @click="handleClose" :style="{zIndex}"></div>
    </transition>

    <!-- 内容的滑入滑出 -->
    <transition :name="`slide-${position}`">
      <div v-if="open" class="_drawer-content" :class="position" @click.stop :style="{borderRadius, border, zIndex}">
        <img v-if="btnCloseVisible"
             class="_close"
             src="@/assets/img/icon-close-black.svg"
             alt="close"
             @click="handleClose">
        <slot></slot>
      </div>
    </transition>
  </div>
  <div class="_drawer-desktop" v-else>
    <!-- 背景的淡入淡出 -->
    <transition name="fade">
      <div v-if="open" class="_drawer-background" :class="{frostedGlass}" @click="handleClose" :style="{zIndex}"></div>
    </transition>

    <!-- 内容的滑入滑出 -->
    <transition :name="`slide-${position}`">
      <div v-if="open" class="_drawer-content" :class="position" @click.stop :style="{borderRadius, border, zIndex}">
        <img v-if="btnCloseVisible"
             class="_close"
             src="@/assets/img/icon-close-black.svg"
             alt="close"
             @click="handleClose">
        <slot></slot>
      </div>
    </transition>
  </div>
</template>

<style scoped lang="scss">
@import "src/assets/config";

._drawer-mobile {
  /* 背景的淡入淡出效果 */
  ._drawer-background {
    width: 100vw;
    height: calc(100vh - $mobile-header-height);
    position: fixed;
    top: $mobile-header-height;
    left: 0;
    z-index: 2;
    transition: opacity 0.3s ease;

    &.frostedGlass {
      background: rgba(255, 255, 255, 0.30);
      backdrop-filter: blur(16px);
    }
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.3s ease;
  }

  .fade-enter-from, .fade-leave-to {
    opacity: 0;
  }

  /* 内容区域的滑入滑出动画 */
  ._drawer-content {
    background-color: white;
    min-width: 100px;
    min-height: 100px;
    position: fixed;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.20);
    z-index: 3;
    transition: transform 0.3s ease;
    overflow: auto;

    ._close {
      width: 20px;
      height: 20px;
      position: absolute;
      top: 16px;
      right: 16px;
      cursor: pointer;
    }
  }

  /* 动态位置控制 */
  ._drawer-content.top {
    top: $mobile-header-height;
    left: 0;
    right: 0;
  }

  ._drawer-content.right {
    right: 0;
    top: $mobile-header-height;
    bottom: 0;
  }

  ._drawer-content.bottom {
    bottom: 0;
    left: 0;
    right: 0;
  }

  ._drawer-content.left {
    left: 0;
    top: $mobile-header-height;
    bottom: 0;
  }

  /* 滑动动画 */
  .slide-top-enter-active, .slide-top-leave-active {
    transition: transform 0.3s ease;
  }

  .slide-top-enter-from, .slide-top-leave-to {
    transform: translateY(-100%);
  }

  .slide-right-enter-active, .slide-right-leave-active {
    transition: transform 0.3s ease;
  }

  .slide-right-enter-from, .slide-right-leave-to {
    transform: translateX(100%);
  }

  .slide-bottom-enter-active, .slide-bottom-leave-active {
    transition: transform 0.3s ease;
  }

  .slide-bottom-enter-from, .slide-bottom-leave-to {
    transform: translateY(100%);
  }

  .slide-left-enter-active, .slide-left-leave-active {
    transition: transform 0.3s ease;
  }

  .slide-left-enter-from, .slide-left-leave-to {
    transform: translateX(-100%);
  }
}

._drawer-desktop {
  /* 背景的淡入淡出效果 */
  ._drawer-background {
    width: 100vw;
    height: calc(100vh - $desktop-header-height);
    position: fixed;
    top: $desktop-header-height;
    left: 0;
    z-index: 2;
    transition: opacity 0.3s ease;

    &.frostedGlass {
      background: rgba(255, 255, 255, 0.30);
      backdrop-filter: blur(16px);
    }
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.3s ease;
  }

  .fade-enter-from, .fade-leave-to {
    opacity: 0;
  }

  /* 内容区域的滑入滑出动画 */
  ._drawer-content {
    background-color: white;
    min-width: 100px;
    min-height: 100px;
    position: fixed;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.20);
    z-index: 3;
    transition: transform 0.3s ease;
    overflow: auto;

    ._close {
      width: 20px;
      height: 20px;
      position: absolute;
      top: 16px;
      right: 16px;
      cursor: pointer;
    }
  }

  /* 动态位置控制 */
  ._drawer-content.top {
    top: $desktop-header-height;
    left: 0;
    right: 0;
  }

  ._drawer-content.right {
    right: 0;
    top: $desktop-header-height;
    bottom: 0;
  }

  ._drawer-content.bottom {
    bottom: 0;
    left: 0;
    right: 0;
  }

  ._drawer-content.left {
    left: 0;
    top: $desktop-header-height;
    bottom: 0;
  }

  /* 滑动动画 */
  .slide-top-enter-active, .slide-top-leave-active {
    transition: transform 0.3s ease;
  }

  .slide-top-enter-from, .slide-top-leave-to {
    transform: translateY(-100%);
  }

  .slide-right-enter-active, .slide-right-leave-active {
    transition: transform 0.3s ease;
  }

  .slide-right-enter-from, .slide-right-leave-to {
    transform: translateX(100%);
  }

  .slide-bottom-enter-active, .slide-bottom-leave-active {
    transition: transform 0.3s ease;
  }

  .slide-bottom-enter-from, .slide-bottom-leave-to {
    transform: translateY(100%);
  }

  .slide-left-enter-active, .slide-left-leave-active {
    transition: transform 0.3s ease;
  }

  .slide-left-enter-from, .slide-left-leave-to {
    transform: translateX(-100%);
  }
}
</style>
